<template>
    <div>
      <TopMenuSubheadline :title='title'/>
      <div class="app-content-box bg-gray">
        <div class="container wrapper pb-5">
          <div class="books-list-masonry">
            <div  v-for="(item, id) in items" :key="id">
              <div class="book-card">
                <div class="book-cover-container">
                  <router-link :to="{name: 'BooksDetails', params: {id: item.id}}">
                    <div class="book-cover">
                      <img
                        :src="item.image ? (coverUrl + item.image) : (coverUrl + 'no_cover.png')"
                      />
                      <div class="bottom-plus-sign" @click.prevent="addBook(item.id, item)"><i class="fas fa-plus-circle"></i></div>
                    </div>
                  </router-link>
                </div>
                <div class="mb-3">
                  <router-link class="text-dark" :to="{name: 'Authors', params: {id: item.author_id}}"><span class="book-title">{{ item.autor }}</span></router-link>
                  <router-link class="text-dark" :to="{name: 'BooksDetails', params: {id: item.id}}"><span class="book-info">{{ item.tytul }}</span></router-link>
                  <div class="stars-wrapper mt-1" v-if=" item.rating > 0">
                    <star-rating :increment="0.5" active-color="#000" :star-size="starSize"
                      inactive-color="#F1F1F1" active-border-color="#000" border-color="#000000"
                      :border-width='borderWidth' :show-rating="false" :read-only="true"
                      :rounded-corners='rounded' :rating="parseFloat(item.rating, 10)" >
                    </star-rating>
                  </div>
                  <div v-if="item.rating > 0" class="rating-size text-dark mt-2">Ocena: {{ratings[id]}}</div>
                </div>
              </div>
            </div>
          </div>
          <infinite-loading @infinite="getAll">
            <div slot="spinner" class="d-flex align-items-center justify-content-center w-100">
              <img src="../assets/loaders/loader_on_overlay.gif" alt="loader" />
            </div>
          </infinite-loading>
        </div>
        <div></div>
        </div>
        <ModalSlot title="Dodaj książkę" v-if="isPopupShown" @close-modal="closeModal">
          <div class="text-center mb-2 modal-main-title">Dodaj książkę</div>
          <div class="modal-wrapper">
            <div class="growing-slide-cover wrapper-cover w-100">
              <img :src="( cover ? coverUrl + cover : coverUrl + 'no_cover.png')" class="slide-cover-image"/>
              </div>
              <div class="book-heading-big text-center mt-3">{{author}}</div>
              <div class="book-heading-small text-center mt-2">{{title}}</div>
          </div>
          <router-link :to="{name: 'BorrowBook', params: {id: book_id}}"><button class="btn-black-border w-100 mt-3 mb-1">Dodaj na półkę</button></router-link>
          <button class="btn-black-border w-100 mt-3 mb-1" @click="saveToLater">Zapisz na później</button>
          <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Zamknij</button>
        </ModalSlot>
    </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import ModalSlot from '../components/ModalSlot'
import StarRating from 'vue-star-rating'
export default {
  name: 'PublishingBookDetails',
  components: {
    TopMenuSubheadline,
    ModalSlot,
    StarRating
  },
  props: {
    result: String,
    name: String
  },
  data () {
    return {
      author_title: this.name,
      list: [],
      isPopupShown: false,
      isLoaded: false,
      book_id: '',
      cover: '',
      author: '',
      title: '',
      page: 1,
      items: [],
      ratings: [],
      starsReadOnly: true,
      rating: 1,
      borderWidth: 2,
      animate: true,
      rounded: true,
      starSize: 18
    }
  },
  methods: {
    getAll ($state) {
      this.$https('/publisher/' + this.result + '?page=' + this.page, {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true
      })
        .then(({ data }) => {
          const res = data.response[0]
          this.title = res.label
          if (res.pages === 1) {
            this.items = res.items
            this.items.forEach(e => {
              if (e.rating % 1 === 0) {
                this.ratings.push(parseFloat(e.rating, 10).toFixed(0))
              } else {
                this.ratings.push(parseFloat(e.rating, 10).toFixed(1))
              }
            })
            $state.complete()
          } else {
            this.page += 1
            if (res.items.length) {
              this.items.push(...res.items)
              this.items.forEach(e => {
                if (e.rating % 1 === 0) {
                  this.ratings.push(parseFloat(e.rating, 10).toFixed(0))
                } else {
                  this.ratings.push(parseFloat(e.rating, 10).toFixed(1))
                }
              })
              $state.loaded()
            } else {
              $state.complete()
            }
          }
        })
        .catch(err => {
          console.log('', err)
        })
    },
    addBook (id, book) {
      this.book_id = id
      this.cover = book.image
      this.author = book.autor
      this.title = book.tytul
      this.isPopupShown = true
    },
    closeModal () {
      this.isPopupShown = false
    },
    saveToLater () {
      const getData = 'status=later&own=1&borrowed=0'
      this.$https('book/' + this.book_id + '/add/' + 0, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.closeModal()
      })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    min-height: 100vh;
  }
  .wrapper {
    margin-top: 60px;
  }
  .bottom-plus-sign {
    position: relative;
    top: -25px;
  }
  .book-title {
    position: relative;
    top: -10px;
  }
  .wrapper-cover {
  display: flex;
  justify-content: center;

    img {
      max-height: 270px;
      max-width: 210px;
    }
  }
  .rating-size {
    font-size: 14px;
  }
</style>
